import { i18n } from '@/plugins/vue-i18n';
import { Toast } from 'vant';

// 403.401 = rpt 驗證錯誤
// 403.402 = 推廣平台的 mt 驗證錯誤

// (以下全部可以顯示資格錯誤, 再以數字區別)
// ex: 資格不符(3), 請洽客服
// 403.403  = kol 資格錯誤, 具有 birc project
// 403.404 = user 已存在相同 reMemberId
// 403.405 = user 已存在相同 google user id
// 403.406 = 其他原因存在 user

const mapErrorCodeToMessage = {
  '403.401': i18n.global.t('error_code_403_401_msg'),
  '403.402': i18n.global.t('error_code_403_402_msg'),

  '403.403': i18n.global.t('error_code_403_403_msg'),
  // '403.404': '會員身份驗證失敗',
  // '403.405': '您的 Google 帳號已參加本次活動囉！',
  // '403.406': '未知原因存在 user，請連繫客服。',
  getMemberErrorMessageByErrorCode: (code: string): string => i18n.global.t('member_verify_error_msg', { code }),
};

const hasErrorByResultCode = (resultCode) => {
  if (/403\.403/.test(resultCode)) {
    Toast({ type: 'fail', message: mapErrorCodeToMessage[resultCode] });
    return true;
  }
  if (/403\.40[4, 5, 6]/.test(resultCode)) {
    Toast({ type: 'fail', message: mapErrorCodeToMessage.getMemberErrorMessageByErrorCode(resultCode) });
    return true;
  }
  if (/403\.40[1, 2]/.test(resultCode)) {
    Toast({ type: 'fail', message: mapErrorCodeToMessage[resultCode] });
    return true;
  }
  return false;
};

export default () => ({
  hasErrorByResultCode,
});
