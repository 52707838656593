import { ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';

export function useCountDown(value = 60, interval = 1000) {
  const count = ref(0);

  const { pause, resume } = useIntervalFn(countDown, interval, {
    immediate: false,
  });

  function countDown() {
    count.value--;

    if (count.value <= 0) {
      pause();
    }
  }

  function init() {
    count.value = value;
  }

  function restart() {
    init();
    resume();
  }

  return {
    count,
    restart,
  };
}
