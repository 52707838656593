import { ref, onMounted } from 'vue';
import { KOLSvc } from '@/services';

import type { ProjectItem } from '@/services/dto/kol.dto';

const projectList = ref<ProjectItem[]>([]);

const getProject = async () => {
  let response;
  try {
    response = await KOLSvc.getProject();
    if (response && response.data.success) {
      projectList.value = response.data.items;
    }
  } catch (error) {
    console.error(error);
  }
}

// HACK: use projectList for cache
const getProjectIdByName = async (name = 'KOL') => {
  if (projectList.value.length === 0) await getProject()
  const KOLProject = projectList.value.find(e => e.name.toUpperCase() === name)
  return KOLProject?.id || 'default KOL'
}

// ! need to use `await`
// ex: const projectId = await KOLProject
const KOLProjectId = getProjectIdByName('KOL')

export const useProject = () => {

  onMounted(async () => {
    if (projectList.value.length === 0) await getProject()
  })
  return { projectList, getProjectIdByName, KOLProjectId };
};
